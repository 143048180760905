import { employeeKindConfigDict } from "CONSTANTS";
import { EmployedPlace, PostEmployee } from "api/manufacturingNew/models";
import { Button } from "components/miloDesignSystem/atoms/button/Button";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { Modal } from "components/miloDesignSystem/atoms/modal";
import { Radio } from "components/miloDesignSystem/atoms/radio";
import { TextField } from "components/miloDesignSystem/atoms/textField";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";
import { DatePicker } from "components/miloDesignSystem/molecules/datepicker/DatePicker";
import { Select } from "components/miloDesignSystem/molecules/select";
import { Formik, FormikHelpers } from "formik";
import { cx, dictToList, getIsoDateFormat } from "utilities";
import { validationSchema } from "../utils/validationSchema";
import { manufacturingEmployeesActions } from "api/manufacturing/employees/actions";
import { handleSubmitResponse } from "utilities/handleSubmitResponse";
import { startOfMonth } from "date-fns";

interface Props {
  close: () => void;
}

export const CreateEmployee = ({ close }: Props) => {
  const employeeKindOptions = dictToList(employeeKindConfigDict).map(({ key, value }) => ({
    text: value.name.toLowerCase(),
    type: MenuItemType.TEXT,
    value: key,
  }));
  const initialValues: PostEmployee = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    kind: "",
    employedPlace: EmployedPlace.INTERNAL,
    dateOfEmployment: getIsoDateFormat(startOfMonth(new Date())),
    company: "",
    number: "",
  };
  const postMutation = manufacturingEmployeesActions.usePostManufacturingEmployee(close);
  const handleSubmit = (values: PostEmployee, actions: FormikHelpers<PostEmployee>) => {
    postMutation.mutate(values, handleSubmitResponse(actions));
  };

  return (
    <Modal
      close={close}
      isOpen
      title={
        <Typography fontSize="20" fontWeight="700">
          Dodaj pracownika
        </Typography>
      }
      width={720}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ handleSubmit, isSubmitting, isValid, setFieldValue, values }) => (
          <form className={cx({ "was-validated": !isValid })} onSubmit={handleSubmit}>
            <div className="mx-3 d-flex flex-column gap-3">
              <TextField.Form<PostEmployee>
                label="Imię"
                name="firstName"
                placeholder="Wpisz imię"
              />
              <TextField.Form<PostEmployee>
                label="Nazwisko"
                name="lastName"
                placeholder="Wpisz nazwisko"
              />
              <TextField.Form<PostEmployee> label="Email" name="email" placeholder="Wpisz email" />
              <TextField.Form<PostEmployee>
                label="Numer telefonu"
                name="phone"
                placeholder="Wpisz numer telefonu"
              />
              <TextField.Form<PostEmployee>
                label="Numer pracownika"
                name="number"
                placeholder="Wpisz numer pracownika"
              />
              <Select.Form<PostEmployee>
                items={employeeKindOptions}
                label="Typ pracownika"
                name="kind"
              />
              <div className="d-flex flex-column gap-1">
                <Typography className="text-uppercase" fontSize="10" fontWeight="700">
                  Gdzie zatrudniony(-na)
                </Typography>
                <div className="d-flex flex-column gap-2">
                  <Radio
                    checked={values.employedPlace === EmployedPlace.INTERNAL}
                    label="wewnętrznie"
                    onClick={() => setFieldValue("employedPlace", EmployedPlace.INTERNAL)}
                    size="sm"
                  />
                  <Radio
                    checked={values.employedPlace === EmployedPlace.EXTERNAL}
                    label="u kontrahenta"
                    onClick={() => setFieldValue("employedPlace", EmployedPlace.EXTERNAL)}
                    size="sm"
                  />
                </div>
              </div>
              <div className="d-flex flex-column gap-1">
                <DatePicker.Form<PostEmployee>
                  label="Data zatrudnienia"
                  isNullable={false}
                  name="dateOfEmployment"
                />
              </div>
            </div>
            <div className="d-flex align-items-center gap-2 mt-4 p-3 borderTop">
              <Button className="text-uppercase" onClick={close} size="medium" variant="gray">
                Anuluj
              </Button>
              <Button
                className="text-uppercase"
                isLoading={isSubmitting}
                size="medium"
                type="submit"
                variant="deepPurple"
              >
                Dodaj
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </Modal>
  );
};
