import { materialsActions } from "api/manufacturing/materials/actions";
import { PageHeader } from "components/common";
import { PageWrapper } from "components/common/pageWrapper/PageWrapper";
import { useTableFeatureConnector } from "components/miloDesignSystem/molecules/table/hooks/useTableFeatureConnector";
import { StateModalHookState, useQuery, useStateModal, useToggle } from "hooks";
import { capitalizeFirstLetter, cx, queryString } from "utilities";
import { useMaterialColumns } from "./useMaterialColumns";
import { Table } from "components/miloDesignSystem/molecules/table";
import { MaterialAvailabilityStatus, MaterialListItem } from "api/manufacturing/materials/models";
import { mainListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import styles from "./DemandAndAvailability.module.css";
import { RightPanel } from "./rightPanel/RightPanel";
import { CreateMaterial } from "./createMaterial/CreateMaterial";
import { OrderMaterial } from "./orderMaterial/OrderMaterialModal";
import { useTableMultiSelect } from "components/miloDesignSystem/molecules/table/hooks/useTableMultiSelect/useTableMultiSelect";
import { TableToolbar } from "components/miloDesignSystem/molecules/tableToolbar/TableToolbar";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiFabric } from "components/miloDesignSystem/atoms/icons/MdiFabric";
import { CommonError } from "components/utils";
import { Spinner } from "components/miloDesignSystem/atoms/spinner";
import { materials } from "components/common/moduleNavigation/moduleConfig/manufacturing/routes/materials";
import { UUID } from "api/types";
import { useParams } from "react-router";

export type OrderMaterialModal = StateModalHookState<{ materials: MaterialListItem[] }>;

export const MaterialList = () => {
  const { query } = useQuery({ exclude: ["panelId"] });
  const { tab } = useParams<{ tab: UUID | "all" }>();
  const search = queryString.stringify({
    ...query,
    categoryId: tab === "all" ? "" : tab,
  });
  const {
    data: materialsDemandAndAvailability,
    error,
    isLoading,
    isPreviousData,
    pagination,
  } = materialsActions.useMaterials(search);
  const categoriesSearch = queryString.stringify({
    pageSize: 999,
  });
  const {
    data: categories,
    error: categoriesError,
    isLoading: areCategoriesLoading,
  } = materialsActions.useMaterialCategories(categoriesSearch);

  const createMaterialModal = useToggle();
  const orderMaterialModal: OrderMaterialModal = useStateModal();

  const tableMultiSelect = useTableMultiSelect({ rows: materialsDemandAndAvailability });
  const tableProps = useTableFeatureConnector({
    rows: materialsDemandAndAvailability,
    withDrawer: "materialsDemandAndAvailability",
    withPagination: { pagination },
    withMultiSelect: tableMultiSelect,
  });

  const columns = useMaterialColumns({ orderMaterialModal });

  return (
    <PageWrapper>
      <PageHeader
        searchInput={{
          label: "Szukaj...",
          tags: [],
        }}
        tabs={
          categories
            ? {
                list: [{ name: "all", label: "Wszystkie" }].concat(
                  categories.map(category => ({
                    name: category.id,
                    label: capitalizeFirstLetter(category.name),
                  })),
                ),
                routesRoot: `manufacturing/${materials.url}/list`,
              }
            : undefined
        }
        title={
          areCategoriesLoading ? (
            <div className="d-flex align-items-center mb-1">
              <Spinner size={20} />
            </div>
          ) : categoriesError ? (
            <CommonError status={categoriesError?._httpStatus_} size="small" />
          ) : (
            undefined
          )
        }
        createButton={{ label: "Dodaj materiał", onClick: createMaterialModal.open }}
        viewLabel="DEMAND_AND_AVAILABILITY"
      />
      <div className="d-flex flex-1 overflow-hidden justify-content-between">
        <Table<MaterialListItem>
          columns={columns}
          error={error}
          isLoading={isLoading || isPreviousData}
          uiSchema={mainListUiSchema}
          {...tableProps}
          overrides={() => {
            return {
              ...tableProps?.overrides?.(),
              defaultPaginationVisibility: true,
              row: row => {
                return {
                  className: cx(
                    {
                      [styles.veryHighStock]:
                        row.stock.stockStatus === MaterialAvailabilityStatus.VERY_HIGH,
                      [styles.veryLowStock]:
                        row.stock.stockStatus === MaterialAvailabilityStatus.VERY_LOW,
                    },
                    tableProps?.overrides?.().row?.(row).className,
                  ),
                };
              },
            };
          }}
        />
        <RightPanel />
        <TableToolbar
          close={tableMultiSelect.clear}
          numberOfSelectedItems={tableMultiSelect.selectedRows.length}
        >
          <Tooltip title="Zamów materiały">
            <IconButton
              icon={MdiFabric}
              variant="transparent"
              theme="dark"
              onClick={() => {
                orderMaterialModal.open({ materials: tableMultiSelect.selectedRows });
                tableMultiSelect.clear();
              }}
            />
          </Tooltip>
        </TableToolbar>
        {createMaterialModal.isOpen && <CreateMaterial close={createMaterialModal.close} />}
        {orderMaterialModal.isOpen && <OrderMaterial orderMaterialModal={orderMaterialModal} />}
      </div>
    </PageWrapper>
  );
};
