import { createApiQuery } from "hooks/createApiQuery";
import { manufacturingUnitsApi } from "./api";
import { createPaginatedApiQuery } from "hooks/createPaginatedQuery";
import { useMutation } from "hooks/useMutation";
import { getAnyErrorKey, getIsoDateFormat, queryString } from "utilities";
import {
  GroupManufacturingItemPayload,
  ManufacturingGroup,
  ManufacturingGroupWithId,
  ManufacturingUnit,
  ManufacturingUnitGroup,
  ManufacturingUnitGroupPriority,
  ManufacturingUnitGroupType,
  ManufacturingUnitListViewDetails,
  SetManufacturingUnitsAsFinished,
} from "./models";
import { assertIsDefined } from "utilities/assertIsDefined";
import immer from "immer";
import { manufacturingUnitsKeys } from "./keys";
import { Pagination, UUID } from "api/types";
import { QueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { PartialOf } from "typeUtilities";
import { useQuery } from "hooks";
import { useDownloadFeedbackToastr } from "components/utils/downloadFeedback/DownloadFeedbackController";
import { useErrorToastr } from "hooks/useErrorToastr";
import { fileDownloader } from "fileDownloader";
import { manufacturingFileFactory } from "api/manufacturingNew/calls";
import { manufacturingStagesUtils } from "utilities/manufacturingStages";
import { useManufacturingStage } from "api/manufacturingNew/hooks";
import { manufacturingActions } from "../actions";

const useGetManufacturingUnitsGroups = createApiQuery(
  manufacturingUnitsApi.getManufacturingUnitsGroups,
);

const useCustomOrderedMaterials = createApiQuery(
  manufacturingUnitsApi.getManufacturingUnitsCustomOrderedMaterials,
);

const useGetManufacturingUnitGroupDetails = createApiQuery(
  manufacturingUnitsApi.getManufacturingUnitsGroupsDetails,
);

const useGetTodoManufacturingUnit = createApiQuery(manufacturingUnitsApi.getTodoManufacturingUnit);

const useGetInProgressGroupedUnits = createPaginatedApiQuery(
  manufacturingUnitsApi.getInProgressGroupedUnits,
);

const useGetTodoManufacturingUnits = createPaginatedApiQuery(
  manufacturingUnitsApi.getTodoManufacturingUnits,
);

const usePrioritizedUnits = createPaginatedApiQuery(manufacturingUnitsApi.getPrioritizedListUnits);
const useNormalUnits = createPaginatedApiQuery(manufacturingUnitsApi.getNormalListUnits);

const useGetReadyManufacturingUnits = createPaginatedApiQuery(
  manufacturingUnitsApi.getReadyManufacturingUnits,
);

const useGetManufacturingGroups = createApiQuery(manufacturingUnitsApi.getManufacturingGroups);

const useManufacturingItemCount = createApiQuery(manufacturingUnitsApi.getManufacturingItemsCount);

const useListUnitDetails = createApiQuery(manufacturingUnitsApi.getListUnit);

const useBulkPatchPriorityManufacturingUnits = (
  setGroupDetails?: React.Dispatch<React.SetStateAction<ManufacturingGroup | null>>,
) => {
  return useMutation(
    manufacturingUnitsApi.patchBulkManufacturingUnits,
    ({ queryClient, toastr }) => ({
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries();
        setGroupDetails?.(prevDetails => {
          assertIsDefined(prevDetails);
          return immer(prevDetails, draft => {
            draft.elements = prevDetails.elements.map(item => {
              if (variables.ids.some(checkedId => checkedId === item.id))
                return {
                  ...item,
                  priority: variables.priority ?? item.priority,
                };
              return item;
            });
          });
        });
      },
      onError: error => {
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    }),
  );
};

const useListUnitPatch = () => {
  return useMutation(
    ({ id, toUpdate }: { id: UUID; toUpdate: PartialOf<ManufacturingUnitListViewDetails> }) => {
      return manufacturingUnitsApi.patchListUnit(toUpdate, id);
    },
    ({ queryUtils }) => ({
      onMutate: args => {
        const prevDetails = queryUtils.handleMutate(
          manufacturingUnitsKeys.listView.listUnitDetails(args.id),
          args.toUpdate,
        );
        const prevPrioritizedList = queryUtils.handlePaginatedListUpdate(
          manufacturingUnitsKeys.listView.prioritizedUnits(),
          args.id,
          args.toUpdate,
        );
        const prevNormalList = queryUtils.handlePaginatedListUpdate(
          manufacturingUnitsKeys.listView.normalUnits(),
          args.id,
          args.toUpdate,
        );

        return { prevDetails, prevPrioritizedList, prevNormalList };
      },
      onError: (error, args, onMutateReturn) => {
        assertIsDefined(onMutateReturn);
        queryUtils.rollback(
          manufacturingUnitsKeys.listView.listUnitDetails(args.id),
          onMutateReturn.prevDetails,
          error,
        );
        queryUtils.rollbackList(
          manufacturingUnitsKeys.listView.prioritizedUnits(),
          onMutateReturn.prevPrioritizedList,
          args.id,
        );
        queryUtils.rollbackList(
          manufacturingUnitsKeys.listView.normalUnits(),
          onMutateReturn.prevNormalList,
          args.id,
        );
      },
    }),
  );
};

const useTodoManufacturingUnitPatch = (hide?: () => void) => {
  const { query } = useQuery();

  return useMutation(
    ({ id, toUpdate }: { id: UUID; toUpdate: PartialOf<ManufacturingUnit> }) => {
      return manufacturingUnitsApi.patchTodoManufacturingUnit(toUpdate, id);
    },

    ({ queryClient, queryUtils }) => ({
      onMutate: args => {
        const prevList = queryUtils.handlePaginatedListUpdate(
          manufacturingUnitsKeys.todoManufacturingUnit.list(),
          args.id,
          args.toUpdate,
        );
        if (query.unitPanelId === args.id) {
          const prevDetails = queryUtils.handleMutate(
            manufacturingUnitsKeys.todoManufacturingUnit.details(args.id),
            args.toUpdate,
          );
          return { prevList, prevDetails };
        }
        return { prevList };
      },
      onSuccess: () => {
        queryClient.invalidateQueries();
        hide?.();
      },
      onError: (error, args, onMutateReturn) => {
        assertIsDefined(onMutateReturn);
        queryUtils.rollbackList(
          manufacturingUnitsKeys.todoManufacturingUnit.list(),
          onMutateReturn.prevList,
          args.id,
        );
        if (onMutateReturn.prevDetails) {
          queryUtils.rollback(
            manufacturingUnitsKeys.todoManufacturingUnit.details(args.id),
            onMutateReturn.prevDetails,
            error,
          );
        }
      },
    }),
  );
};

const useReadyManufacturingUnitPatch = (hide?: () => void) => {
  const { query } = useQuery();
  return useMutation(
    ({ id, toUpdate }: { id: UUID; toUpdate: PartialOf<ManufacturingUnit> }) => {
      return manufacturingUnitsApi.patchTodoManufacturingUnit(toUpdate, id);
    },
    ({ queryClient, queryUtils }) => ({
      onMutate: args => {
        const prevList = queryUtils.handlePaginatedListUpdate(
          manufacturingUnitsKeys.readyManufacturingUnit.list(),
          args.id,
          args.toUpdate,
        );
        if (query.unitPanelId === args.id) {
          const prevDetails = queryUtils.handleMutate(
            manufacturingUnitsKeys.todoManufacturingUnit.details(args.id),
            args.toUpdate,
          );
          return { prevList, prevDetails };
        }
        return { prevList };
      },
      onSuccess: () => {
        queryClient.invalidateQueries();
        hide?.();
      },
      onError: (error, args, onMutateReturn) => {
        assertIsDefined(onMutateReturn);
        queryUtils.rollbackList(
          manufacturingUnitsKeys.readyManufacturingUnit.list(),
          onMutateReturn.prevList,
          args.id,
        );
        if (onMutateReturn.prevDetails) {
          queryUtils.rollback(
            manufacturingUnitsKeys.todoManufacturingUnit.details(args.id),
            onMutateReturn.prevDetails,
            error,
          );
        }
      },
    }),
  );
};

const useBulkUpdateManufacturingUnitsList = () => {
  return useMutation(
    manufacturingUnitsApi.postBulkManufacturingUnitsList,
    ({ queryClient, toastr }) => ({
      onSuccess: () => queryClient.invalidateQueries(),
      onError: error => {
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    }),
  );
};

const useGroupManufacturingUnits = (
  setGroupDetails?: React.Dispatch<React.SetStateAction<ManufacturingGroup | null>>,
) => {
  return useMutation(
    (data: GroupManufacturingItemPayload) => {
      const getUniqueAttributes = (
        attributes: GroupManufacturingItemPayload["attributes"],
      ): GroupManufacturingItemPayload["attributes"] => {
        const uniqueAttributes = new Set();
        return attributes.filter(attribute => {
          const stringifiedAttribute = JSON.stringify(attribute);
          return uniqueAttributes.has(stringifiedAttribute)
            ? false
            : uniqueAttributes.add(stringifiedAttribute);
        });
      };
      return manufacturingUnitsApi.postGroupManufacturingUnits({
        ...data,
        attributes: getUniqueAttributes(data.attributes),
      });
    },
    ({ queryClient, toastr }) => ({
      onSuccess: (_, args) => {
        setGroupDetails?.(prevGroups => {
          assertIsDefined(prevGroups);
          return {
            ...prevGroups,
            elements: prevGroups.elements.filter(
              unit => !args.manufacturingWorkingUnitIds.includes(unit.id),
            ),
          };
        });
        queryClient.invalidateQueries();
        toastr.open({
          type: "success",
          title: "Udało się!",
          text: "Zgrupowano i przeniesiono do 'w trakcie'",
        });
      },
      onError: error => {
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    }),
  );
};

const useDragUnitToInProgressSection = () => {
  const { stageId } = useParams<{ stageId: UUID }>();
  const { query, updateQuery } = useQuery();
  const { data: manufacturingStage } = useManufacturingStage(stageId);
  const { data: defaultFilters } = manufacturingActions.useStageBoardDefaultAttributesKind(
    queryString.stringify({
      schemaStage: stageId,
    }),
  );
  const attributesKinds = manufacturingStagesUtils.getAttributesKinds(defaultFilters!);
  const inProgressSearch = manufacturingStagesUtils.getInProgressColumnSearch(
    query,
    stageId,
    manufacturingStage!.boardFormat,
    attributesKinds,
  );

  const handleRollback = (
    inProgressList: ManufacturingUnitGroup[],
    units: Pagination<ManufacturingUnit>,
    unitSearchParams: string,
    queryClient: QueryClient,
  ) => {
    queryClient.setQueryData<ManufacturingUnitGroup[]>(
      manufacturingUnitsKeys.manufacturingUnitGroup.list(inProgressSearch),
      inProgressList,
    );
    queryClient.setQueryData<Pagination<ManufacturingUnit>>(
      manufacturingUnitsKeys.todoManufacturingUnit.list(unitSearchParams),
      units,
    );
  };

  return useMutation(
    ({ data }: { data: GroupManufacturingItemPayload; todoUnitSearchParams: string }) => {
      return manufacturingUnitsApi.postGroupManufacturingUnits(data);
    },
    ({ queryClient, toastr }) => ({
      onMutate: args => {
        const units = queryClient.getQueryData<Pagination<ManufacturingUnit>>(
          manufacturingUnitsKeys.todoManufacturingUnit.list(args.todoUnitSearchParams),
        );
        assertIsDefined(units);
        const inProgressList = queryClient.getQueryData<ManufacturingUnitGroup[]>(
          manufacturingUnitsKeys.manufacturingUnitGroup.list(inProgressSearch),
        );
        assertIsDefined(inProgressList);
        const unitToAdd = units.results.find(
          unit => unit.id === args.data.manufacturingWorkingUnitIds[0],
        );
        assertIsDefined(unitToAdd);

        queryClient.setQueryData<ManufacturingUnitGroup[]>(
          manufacturingUnitsKeys.manufacturingUnitGroup.list(inProgressSearch),
          inProgressList => {
            assertIsDefined(inProgressList);
            return [
              {
                attributes: unitToAdd.attributeValues,
                createdAt: unitToAdd.createdAt,
                elementsCount: {
                  A: unitToAdd.priority === ManufacturingUnitGroupPriority.A ? 1 : 0,
                  B: unitToAdd.priority === ManufacturingUnitGroupPriority.B ? 1 : 0,
                  C: unitToAdd.priority === ManufacturingUnitGroupPriority.C ? 1 : 0,
                  D: 0,
                  E: 0,
                },
                employee: unitToAdd.employee,
                group: null,
                id: unitToAdd.id,
                implementedBy: unitToAdd.implementedBy,
                isCancelled: unitToAdd.isCancelled,
                isDeclined: unitToAdd.isDeclined,
                manufacturer: unitToAdd.manufacturer,
                manufacturingItems: [{ id: unitToAdd.id }],
                priority: unitToAdd.priority,
                scheduledAt: null,
                signature: unitToAdd.signature,
                startedAt: getIsoDateFormat(new Date()),
                type: ManufacturingUnitGroupType.MANUFACTURING_WORKING_UNIT,
              },
              ...inProgressList,
            ];
          },
        );

        queryClient.setQueryData<Pagination<ManufacturingUnit>>(
          manufacturingUnitsKeys.todoManufacturingUnit.list(args.todoUnitSearchParams),
          unitsList => {
            assertIsDefined(unitsList);
            assertIsDefined(unitToAdd);
            return {
              ...unitsList,
              results: unitsList.results.filter(unit => unit.id !== unitToAdd.id),
            };
          },
        );

        return { inProgressList, units };
      },
      onSuccess: (_, args) => {
        if (query.unitPanelId === args.data.manufacturingWorkingUnitIds[0]) {
          updateQuery({ unitPanelId: "" });
        }
        queryClient.invalidateQueries();
      },
      onError: (error, args, onMutateReturn) => {
        assertIsDefined(onMutateReturn);
        handleRollback(
          onMutateReturn.inProgressList!,
          onMutateReturn.units!,
          args.todoUnitSearchParams,
          queryClient,
        );
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    }),
  );
};

const useDragGroupToInProgressSection = () => {
  const { stageId } = useParams<{ stageId: UUID }>();
  const { query, updateQuery } = useQuery();
  const { data: manufacturingStage } = useManufacturingStage(stageId);
  const { data: defaultFilters } = manufacturingActions.useStageBoardDefaultAttributesKind(
    queryString.stringify({
      schemaStage: stageId,
    }),
  );
  const attributesKinds = manufacturingStagesUtils.getAttributesKinds(defaultFilters!);
  const inProgressSearch = manufacturingStagesUtils.getInProgressColumnSearch(
    query,
    stageId,
    manufacturingStage!.boardFormat,
    attributesKinds,
  );

  const handleRollback = (
    inProgressList: ManufacturingUnitGroup[],
    queryClient: QueryClient,
    setTodoGroupsWithId: React.Dispatch<React.SetStateAction<ManufacturingGroupWithId[]>>,
    groupToAdd: ManufacturingGroupWithId,
  ) => {
    queryClient.setQueryData<ManufacturingUnitGroup[]>(
      manufacturingUnitsKeys.manufacturingUnitGroup.list(inProgressSearch),
      inProgressList,
    );
    setTodoGroupsWithId(prevGroups => [...prevGroups, groupToAdd]);
  };

  const getPriorityCount = (
    group: ManufacturingGroupWithId,
    priority: ManufacturingUnitGroupPriority,
  ): number => group.elements.filter(element => element.priority === priority).length;

  return useMutation(
    ({
      data,
    }: {
      data: GroupManufacturingItemPayload;
      todoGroupsWithId: ManufacturingGroupWithId[];
      uniqueId: UUID;
      setTodoGroupsWithId: React.Dispatch<React.SetStateAction<ManufacturingGroupWithId[]>>;
    }) => {
      return manufacturingUnitsApi.postGroupManufacturingUnits(data);
    },
    ({ queryClient, toastr }) => ({
      onMutate: args => {
        const inProgressList = queryClient.getQueryData<ManufacturingUnitGroup[]>(
          manufacturingUnitsKeys.manufacturingUnitGroup.list(inProgressSearch),
        );
        assertIsDefined(args.todoGroupsWithId);
        const groupToAdd = args.todoGroupsWithId.find(group => group.id === args.uniqueId);
        assertIsDefined(groupToAdd);

        queryClient.setQueryData<ManufacturingUnitGroup[]>(
          manufacturingUnitsKeys.manufacturingUnitGroup.list(inProgressSearch),
          inProgressList => {
            assertIsDefined(inProgressList);
            return [
              {
                attributes: groupToAdd.attributesValues,
                createdAt: getIsoDateFormat(new Date()),
                elementsCount: {
                  A: getPriorityCount(groupToAdd, ManufacturingUnitGroupPriority.A),
                  B: getPriorityCount(groupToAdd, ManufacturingUnitGroupPriority.B),
                  C: getPriorityCount(groupToAdd, ManufacturingUnitGroupPriority.C),
                  D: 0,
                  E: 0,
                },
                employee: null,
                group: null,
                id: groupToAdd.id,
                isCancelled: false,
                isDeclined: false,
                implementedBy: null,
                manufacturer: null,
                manufacturingItems: groupToAdd.elements.map(unit => ({
                  id: unit.id,
                })),
                priority: "",
                scheduledAt: null,
                signature: groupToAdd.modelName,
                startedAt: getIsoDateFormat(new Date()),
                type: ManufacturingUnitGroupType.GROUP,
              },
              ...inProgressList,
            ];
          },
        );

        args.setTodoGroupsWithId(prevGroups =>
          prevGroups.filter(group => group.id !== args.uniqueId),
        );

        return { inProgressList, groupToAdd };
      },
      onSuccess: (_, args) => {
        if (query.unitPanelId === args.uniqueId) {
          updateQuery({ unitPanelId: "" });
        }
        queryClient.invalidateQueries();
      },
      onError: (error, args, onMutateReturn) => {
        assertIsDefined(onMutateReturn);
        handleRollback(
          onMutateReturn.inProgressList!,
          queryClient,
          args.setTodoGroupsWithId,
          onMutateReturn.groupToAdd,
        );
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    }),
  );
};

const useCancelManufacturingItem = (close: () => void) => {
  return useMutation(
    manufacturingUnitsApi.postManufacturingUnitCancel,
    ({ queryClient, toastr }) => ({
      onSuccess: () => {
        queryClient.invalidateQueries();
        close();
      },
      onError: error => {
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    }),
  );
};

const useSetManufacturingUnitsAsFinished = () => {
  const { updateQuery } = useQuery();

  return useMutation(
    manufacturingUnitsApi.postSetManufacturingUnitsAsFinished,
    ({ queryClient, toastr }) => ({
      onSuccess: () => {
        updateQuery({ unitPanelId: "" });
        queryClient.invalidateQueries();
        toastr.open({
          type: "success",
          title: "Udało się!",
          text: "Przeniesiono do 'gotowe'",
        });
      },
      onError: error => {
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    }),
  );
};

const useDragUnitToReadySection = () => {
  const { stageId } = useParams<{ stageId: UUID }>();
  const { query, updateQuery } = useQuery();
  const { data: manufacturingStage } = useManufacturingStage(stageId);
  const { data: defaultFilters } = manufacturingActions.useStageBoardDefaultAttributesKind(
    queryString.stringify({
      schemaStage: stageId,
    }),
  );
  const attributesKinds = manufacturingStagesUtils.getAttributesKinds(defaultFilters!);
  const inProgressSearch = manufacturingStagesUtils.getInProgressColumnSearch(
    query,
    stageId,
    manufacturingStage!.boardFormat,
    attributesKinds,
  );

  const handleRollback = (
    inProgressList: ManufacturingUnitGroup[],
    units: Pagination<ManufacturingUnit>,
    unitSearchParams: string,
    queryClient: QueryClient,
  ) => {
    queryClient.setQueryData<ManufacturingUnitGroup[]>(
      manufacturingUnitsKeys.manufacturingUnitGroup.list(inProgressSearch),
      inProgressList,
    );
    queryClient.setQueryData<Pagination<ManufacturingUnit>>(
      manufacturingUnitsKeys.readyManufacturingUnit.list(unitSearchParams),
      units,
    );
  };

  return useMutation(
    ({
      data,
    }: {
      data: Partial<SetManufacturingUnitsAsFinished>;
      readyUnitSearchParams: string;
    }) => {
      return manufacturingUnitsApi.postSetManufacturingUnitsAsFinished(data);
    },
    ({ queryClient, toastr }) => ({
      onMutate: args => {
        const inProgressList = queryClient.getQueryData<ManufacturingUnitGroup[]>(
          manufacturingUnitsKeys.manufacturingUnitGroup.list(inProgressSearch),
        );
        assertIsDefined(inProgressList);

        const readyList = queryClient.getQueryData<Pagination<ManufacturingUnit>>(
          manufacturingUnitsKeys.readyManufacturingUnit.list(args.readyUnitSearchParams),
        );
        assertIsDefined(readyList);

        const unitToAdd = inProgressList.find(
          unit => unit.id === args.data.manufacturingWorkingUnitsIds![0],
        );
        assertIsDefined(unitToAdd);

        queryClient.setQueryData<ManufacturingUnitGroup[]>(
          manufacturingUnitsKeys.manufacturingUnitGroup.list(inProgressSearch),
          inProgressList => {
            assertIsDefined(inProgressList);
            return inProgressList.filter(unit => unit.id !== unitToAdd.id);
          },
        );

        queryClient.setQueryData<Pagination<ManufacturingUnit>>(
          manufacturingUnitsKeys.readyManufacturingUnit.list(args.readyUnitSearchParams),
          readyList => {
            assertIsDefined(readyList);
            return {
              ...readyList,
              results: [
                ...readyList.results,
                {
                  attributeValues: unitToAdd.attributes,
                  createdAt: unitToAdd.createdAt,
                  manufacturingItem: "",
                  employee: null,
                  finishedAt: getIsoDateFormat(new Date()),
                  id: unitToAdd.id,
                  implementedBy: unitToAdd.implementedBy,
                  isCancelled: unitToAdd.isCancelled,
                  isDeclined: unitToAdd.isDeclined,
                  isOutdated: false,
                  manufacturer: unitToAdd.manufacturer,
                  manufacturingItemId: "",
                  manufacturingItemIdentity: "",
                  name: unitToAdd.signature,
                  note: "",
                  orderSignature: "",
                  priority: unitToAdd.priority as ManufacturingUnitGroupPriority,
                  priorityOrder: null,
                  scheduledAt: unitToAdd.scheduledAt,
                  signature: unitToAdd.signature,
                  startedAt: "",
                  status: "READY",
                },
              ],
            };
          },
        );

        return { inProgressList, readyList };
      },
      onSuccess: (_, args) => {
        if (query.unitPanelId === args.data.manufacturingWorkingUnitsIds![0]) {
          updateQuery({ unitPanelId: "" });
        }
        queryClient.invalidateQueries();
      },
      onError: (error, args, onMutateReturn) => {
        assertIsDefined(onMutateReturn);
        handleRollback(
          onMutateReturn.inProgressList!,
          onMutateReturn.readyList!,
          args.readyUnitSearchParams,
          queryClient,
        );
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    }),
  );
};

const useDragGroupToReadySection = () => {
  const { stageId } = useParams<{ stageId: UUID }>();
  const { query, updateQuery } = useQuery();
  const { data: manufacturingStage } = useManufacturingStage(stageId);
  const { data: defaultFilters } = manufacturingActions.useStageBoardDefaultAttributesKind(
    queryString.stringify({
      schemaStage: stageId,
    }),
  );
  const attributesKinds = manufacturingStagesUtils.getAttributesKinds(defaultFilters!);
  const inProgressSearch = manufacturingStagesUtils.getInProgressColumnSearch(
    query,
    stageId,
    manufacturingStage!.boardFormat,
    attributesKinds,
  );

  const handleRollback = (
    inProgressList: ManufacturingUnitGroup[],
    units: Pagination<ManufacturingUnit>,
    unitSearchParams: string,
    queryClient: QueryClient,
  ) => {
    queryClient.setQueryData<ManufacturingUnitGroup[]>(
      manufacturingUnitsKeys.manufacturingUnitGroup.list(inProgressSearch),
      inProgressList,
    );
    queryClient.setQueryData<Pagination<ManufacturingUnit>>(
      manufacturingUnitsKeys.readyManufacturingUnit.list(unitSearchParams),
      units,
    );
  };

  return useMutation(
    ({
      data,
    }: {
      data: Partial<SetManufacturingUnitsAsFinished>;
      readyUnitSearchParams: string;
    }) => {
      return manufacturingUnitsApi.postSetManufacturingUnitsAsFinished(data);
    },
    ({ queryClient, toastr }) => ({
      onMutate: args => {
        const inProgressList = queryClient.getQueryData<ManufacturingUnitGroup[]>(
          manufacturingUnitsKeys.manufacturingUnitGroup.list(inProgressSearch),
        );
        assertIsDefined(inProgressList);

        const readyList = queryClient.getQueryData<Pagination<ManufacturingUnit>>(
          manufacturingUnitsKeys.readyManufacturingUnit.list(args.readyUnitSearchParams),
        );
        assertIsDefined(readyList);

        const groupToAdd = inProgressList.find(
          unit => unit.id === args.data.manufacturingWorkingUnitGroupsIds![0],
        );
        assertIsDefined(groupToAdd);

        queryClient.setQueryData<ManufacturingUnitGroup[]>(
          manufacturingUnitsKeys.manufacturingUnitGroup.list(inProgressSearch),
          inProgressList => {
            assertIsDefined(inProgressList);
            return inProgressList.filter(unit => unit.id !== groupToAdd.id);
          },
        );

        queryClient.setQueryData<Pagination<ManufacturingUnit>>(
          manufacturingUnitsKeys.readyManufacturingUnit.list(args.readyUnitSearchParams),
          readyList => {
            assertIsDefined(readyList);
            return {
              ...readyList,
              results: [
                ...readyList.results,
                {
                  attributeValues: groupToAdd.attributes,
                  manufacturingItem: "",
                  createdAt: groupToAdd.createdAt,
                  employee: null,
                  finishedAt: getIsoDateFormat(new Date()),
                  id: groupToAdd.id,
                  implementedBy: groupToAdd.implementedBy,
                  isCancelled: groupToAdd.isCancelled,
                  isDeclined: groupToAdd.isDeclined,
                  isOutdated: false,
                  manufacturer: groupToAdd.manufacturer,
                  manufacturingItemId: "",
                  manufacturingItemIdentity: "",
                  name: groupToAdd.signature,
                  note: "",
                  orderSignature: "",
                  priority: groupToAdd.priority as ManufacturingUnitGroupPriority,
                  priorityOrder: null,
                  scheduledAt: groupToAdd.scheduledAt,
                  signature: groupToAdd.signature,
                  startedAt: "",
                  status: "READY",
                },
              ],
            };
          },
        );

        return { inProgressList, readyList };
      },
      onSuccess: (_, args) => {
        if (query.unitPanelId === args.data.manufacturingWorkingUnitGroupsIds![0]) {
          updateQuery({ unitPanelId: "" });
        }
        queryClient.invalidateQueries();
      },
      onError: (error, args, onMutateReturn) => {
        assertIsDefined(onMutateReturn);
        handleRollback(
          onMutateReturn.inProgressList!,
          onMutateReturn.readyList!,
          args.readyUnitSearchParams,
          queryClient,
        );
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    }),
  );
};

const useDownloadManufacturingItemPdf = () => {
  const downloadFeedbackToastr = useDownloadFeedbackToastr();
  const handleErrorMessage = useErrorToastr();

  async function handleDownloadManufacturingItemPdf(
    manufacturingItemIds: UUID[],
    signature: string,
  ) {
    const tstr = downloadFeedbackToastr.open({ type: "pdf" });
    const { url, name } = manufacturingFileFactory.manufacturingItemPdf(
      manufacturingItemIds,
      signature,
    );
    const response = await fileDownloader({
      onProgress: tstr.updateProgress,
      url,
      name,
      type: "pdf",
    });
    if (response.status === "success") {
      tstr.lazyClose();
    } else {
      tstr.close();
      handleErrorMessage(response);
    }
  }

  return handleDownloadManufacturingItemPdf;
};

const useInProgressManufacturingUnitPatch = (search: string, hide?: () => void) => {
  const { query } = useQuery();

  const handleRollback = (inProgressList: ManufacturingUnitGroup[], queryClient: QueryClient) => {
    queryClient.setQueryData<ManufacturingUnitGroup[]>(
      manufacturingUnitsKeys.manufacturingUnitGroup.list(search),
      inProgressList,
    );
  };

  return useMutation(
    ({ id, toUpdate }: { id: UUID; toUpdate: PartialOf<ManufacturingUnit> }) => {
      return manufacturingUnitsApi.patchTodoManufacturingUnit(toUpdate, id);
    },
    ({ queryClient, queryUtils }) => ({
      onMutate: args => {
        const inProgressList = queryClient.getQueryData<ManufacturingUnitGroup[]>(
          manufacturingUnitsKeys.manufacturingUnitGroup.list(search),
        );
        assertIsDefined(inProgressList);

        queryClient.setQueryData<ManufacturingUnitGroup[]>(
          manufacturingUnitsKeys.manufacturingUnitGroup.list(search),
          currentList => {
            assertIsDefined(currentList);
            console.log({ currentList, args });
            return immer(currentList, draft => {
              const unit = draft.find(unit => unit.id === args.id);
              if (unit) {
                Object.assign(unit, args.toUpdate);
              }
            });
          },
        );

        if (query.unitPanelId === args.id) {
          const prevDetails = queryUtils.handleMutate(
            manufacturingUnitsKeys.manufacturingUnitGroup.details(args.id),
            args.toUpdate,
          );
          return { inProgressList, prevDetails };
        }
        return { inProgressList };
      },
      onSuccess: (_, args) => {
        queryClient.invalidateQueries(manufacturingUnitsKeys.manufacturingUnitGroup.list(search));
        queryClient.invalidateQueries(
          manufacturingUnitsKeys.manufacturingUnitGroup.details(args.id),
        );
        hide?.();
      },
      onError: (error, args, onMutateReturn) => {
        assertIsDefined(onMutateReturn);
        handleRollback(onMutateReturn.inProgressList, queryClient);
        if (onMutateReturn?.prevDetails) {
          queryUtils.rollback(
            manufacturingUnitsKeys.manufacturingUnitGroup.details(args.id),
            onMutateReturn.prevDetails,
            error,
          );
        }
      },
    }),
  );
};

export const manufacturingUnitsActions = {
  useBulkPatchPriorityManufacturingUnits,
  useBulkUpdateManufacturingUnitsList,
  useCancelManufacturingItem,
  useCustomOrderedMaterials,
  useDownloadManufacturingItemPdf,
  useDragGroupToInProgressSection,
  useDragUnitToInProgressSection,
  useDragGroupToReadySection,
  useDragUnitToReadySection,
  useGetInProgressGroupedUnits,
  useGetManufacturingUnitsGroups,
  useGetManufacturingGroups,
  useGetManufacturingUnitGroupDetails,
  useGetReadyManufacturingUnits,
  useGetTodoManufacturingUnits,
  useGetTodoManufacturingUnit,
  useGroupManufacturingUnits,
  useListUnitPatch,
  useListUnitDetails,
  useManufacturingItemCount,
  useNormalUnits,
  usePrioritizedUnits,
  useTodoManufacturingUnitPatch,
  useSetManufacturingUnitsAsFinished,
  useInProgressManufacturingUnitPatch,
  useReadyManufacturingUnitPatch,
};
