import { EmptyValue } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { usePatchTradingDocumentMutation } from "api/trading-documents/hooks";
import styles from "../../RightPanel.module.css";
import {
  ExpectedPaymentForm,
  InvoiceType,
  TradingDocumentType,
} from "api/trading-documents/models";
import { UUID } from "api/types";
import { cx } from "utilities";
import { tradingDocumentConstants } from "constants/tradingDocuments";
import { TextField } from "components/miloDesignSystem/atoms/textField";

interface Props {
  expectedPaymentForm: ExpectedPaymentForm;
  id: UUID;
  invoiceType: InvoiceType;
  type: TradingDocumentType;
}

export const PaymentForm = ({ expectedPaymentForm, id, invoiceType, type }: Props) => {
  if (invoiceType !== "SALES" && type !== "RECEIPT" && Boolean(expectedPaymentForm))
    return (
      <div>
        <span
          className={cx("body-14-500", {
            [styles.fontStyle]: expectedPaymentForm !== "CASH" && expectedPaymentForm !== "ONLINE",
          })}
        >
          {expectedPaymentForm === "CASH" || expectedPaymentForm === "ONLINE"
            ? tradingDocumentConstants.expectedPaymentFormDict[expectedPaymentForm]
            : expectedPaymentForm}
        </span>
      </div>
    );

  if (invoiceType === "SALES")
    return (
      <div>
        <TextField.Async
          mutationHook={usePatchTradingDocumentMutation}
          placeholder="Wpisz formę płatności"
          size="small"
          transformQueryData={expectedPaymentForm => ({
            id,
            expectedPaymentForm,
          })}
          value={
            expectedPaymentForm === "CASH" || expectedPaymentForm === "ONLINE"
              ? tradingDocumentConstants.expectedPaymentFormDict[expectedPaymentForm]
              : expectedPaymentForm
          }
        />
      </div>
    );

  return <EmptyValue className="italic" fontSize="14" fontWeight="500" />;
};
