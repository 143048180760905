import { warehouseActions } from "api/warehouse/actions";
import { Spinner } from "components/miloDesignSystem/atoms/spinner/Spinner";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";
import { CommonError } from "components/utils";
import { SectionWrapper } from "pages/crm/customers/customerDetails/components/sectionWrapper/SectionWrapper";
import { useParams } from "react-router";
import { getAnyErrorKey, queryString } from "utilities";
import packageIcon from "assets/images/packageEmpty.svg";
import styles from "./PackagesTab.module.css";
import { MdiAdd } from "components/miloDesignSystem/atoms/icons/MdiAdd";
import { Button } from "components/miloDesignSystem/atoms/button/Button";
import { PackageListItem } from "../../../../../../../api/warehouse/models";
import { useTableFeatureConnector } from "components/miloDesignSystem/molecules/table/hooks/useTableFeatureConnector";
import { usePackagesColumns } from "./usePackagesColumns";
import { Table } from "components/miloDesignSystem/molecules/table/Table";
import { useState } from "react";
import { AddPackage } from "./addPackage/AddPackage";
import { MdiBoxAdd } from "components/miloDesignSystem/atoms/icons/MdiBoxAdd";
import { MdiBoxRemove } from "components/miloDesignSystem/atoms/icons/MdiBoxRemove";
import { indexesActions } from "api/manufacturing/indexes/actions";
import { MdiPackage } from "components/miloDesignSystem/atoms/icons/MdiPackage";

export const PackagesTab = () => {
  const { indexId } = useParams<{ indexId: string }>();
  const search = queryString.stringify({
    index: indexId,
  });
  const {
    data: indexPackages,
    error,
    isLoading,
    isPreviousData,
    isRefetching,
    pagination,
  } = warehouseActions.usePackages(search, {
    enabled: Boolean(indexId),
  });
  const [isAddingPackage, setIsAddingPackage] = useState(false);
  const detachPackagesMutation = indexesActions.useBulkUpddateCustomMadePackages();
  const bindPackagesMutation = indexesActions.useBulkUpddateCustomMadePackages();
  const generatePackagesMutation = indexesActions.useGeneratePackagesForIndexes();

  const tableProps = useTableFeatureConnector({
    rows: indexPackages,
    withPagination: { pagination },
  });
  const columns = usePackagesColumns();

  if (isLoading)
    return (
      <SectionWrapper
        className="mr-3 mb-3 d-flex flex-1 justify-content-center align-items-center p-3 pb-4"
        width="100%"
        minHeight={516}
      >
        <Spinner size={30} />
      </SectionWrapper>
    );

  if (error)
    return (
      <SectionWrapper
        className="mr-3 mb-3 d-flex flex-1 justify-content-center align-items-center p-3 pb-4"
        width="100%"
        minHeight={516}
      >
        <CommonError status={error._httpStatus_} text={getAnyErrorKey(error)} />
      </SectionWrapper>
    );

  if (!indexPackages.length && !isAddingPackage && !isRefetching)
    return (
      <div className="d-flex flex-column align-items-center mt-4">
        <img alt="brak numerów obcych" src={packageIcon} className={styles.emptyIcon} />
        <Typography fontSize="16" fontWeight="700" className="my-2">
          Jeszcze nie dodano paczek
        </Typography>
        <Typography color="neutralBlack48" fontSize="14" fontWeight="500">
          Dodaj pierwszą paczkę klikając w przycisk poniżej
        </Typography>
        <div className="pt-4">
          <Button
            onClick={() => setIsAddingPackage(true)}
            size="medium"
            startIcon={MdiAdd}
            variant="deepPurple"
          >
            Dodaj paczkę
          </Button>
        </div>
      </div>
    );

  return (
    <div className="d-flex flex-column pb-3 flex-1">
      <div className="d-flex align-items-center justify-content-end gap-2 pb-2 px-3 mt-2">
        <Button
          className="text-uppercase"
          isLoading={generatePackagesMutation.isLoading}
          onClick={() =>
            generatePackagesMutation.mutate({
              indexes: [indexId],
            })
          }
          size="small"
          startIcon={MdiPackage}
          variant="gray"
        >
          Twórz paczki dla indeksu
        </Button>
        <Button
          className="text-uppercase"
          isLoading={bindPackagesMutation.isLoading}
          onClick={() =>
            bindPackagesMutation.mutate({
              indexesIds: [Number(indexId)],
              isCustomMade: true,
            })
          }
          size="small"
          startIcon={MdiBoxAdd}
          variant="gray"
        >
          Połącz paczki z zamówieniem
        </Button>
        <Button
          className="text-uppercase"
          isLoading={detachPackagesMutation.isLoading}
          onClick={() =>
            detachPackagesMutation.mutate({
              indexesIds: [Number(indexId)],
              isCustomMade: false,
            })
          }
          size="small"
          startIcon={MdiBoxRemove}
          variant="gray"
        >
          Rozłącz paczki od zamówienia
        </Button>
      </div>
      <div className="mb-2">
        <Table<PackageListItem>
          columns={columns}
          isLoading={isLoading || isPreviousData || isRefetching}
          error={error}
          uiSchema={{
            header: {
              backgroundColor: "neutralWhite100",
            },
            cell: {
              height: "36",
            },
          }}
          {...tableProps}
          overrides={() => {
            return {
              ...tableProps?.overrides?.(),
              noResultComponent: <div className="mb-3" />,
            };
          }}
        />
      </div>
      {isAddingPackage && <AddPackage setIsAddingPackage={setIsAddingPackage} />}
      {!isAddingPackage && (
        <Button
          size="small"
          variant="gray"
          onClick={() => setIsAddingPackage(true)}
          className="ml-3 mt-2 text-uppercase"
          startIcon={MdiAdd}
        >
          Dodaj paczkę
        </Button>
      )}
    </div>
  );
};
